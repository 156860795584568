<template>
	<v-container fluid>
		<div class="d-flex py-2">
			<v-spacer />
			<v-btn
				:disabled="selected.length == 0"
				:loading="!!downloadingFile"
				small
				color="primary"
				text
				@click="downloadSelected"
			>
				<v-icon left>mdi-download</v-icon>
				Descargar seleccionados
			</v-btn>
		</div>
		<v-card>
			<v-data-table
				:headers="documentosHeader"
				:items="documentos"
				v-model="selected"
				item-key="nombre"
				show-select class="fixed-checkbox"
				:single-select="false"
				checkbox-color="secondary"
				:footer-props="{ showFirstLastPage: true, showCurrentPage: true }"
			>
				<template v-slot:body.prepend="{ headers }">
					<table-filters
						:headers="headers"
						v-model="inlineFilters"
					></table-filters>
				</template>

				<template v-slot:item.nombre="{ item }">
					<v-chip
						style="
							height: 36px;
							min-width: 64px;
							font-weight: 500;
							letter-spacing: 0.0892857143em;
						"
						color="transparent"
						text-color="secondary"
						outlined
						label
						>{{ item.nombre }}</v-chip
					>
				</template>

				<template v-slot:item.acciones="{ item }">
					<v-btn
						@click="downloadFile(item.nombre, true)"
						dark
						text
						color="secondary"
						:loading="downloadingFile == item.nombre"
					>
						<v-icon left>mdi-download</v-icon>Descargar
					</v-btn>
					<v-btn
						@click="downloadFile(item.nombre, false)"
						dark
						text
						color="secondary"
						:loading="downloadingFile == item.nombre"
					>
						<v-icon left>mdi-eye</v-icon>Ver
					</v-btn>
				</template>
			</v-data-table>
		</v-card>
	</v-container>
</template>

<script>
import { perColumnFilter, downloadFile } from "@/utils/index.js";
import extensions from "@/utils/mimeExtensions.js";
// import axios from "redaxios";

export default {
	components: {
		TableFilters: () => import("@/components/TableFilters.vue"),
	},
	data() {
		return {
			downloadingFile: false,
			btns: {},
			search: "",
			documentos: [],
			selected: [],
			documentosHeader: [
				{
					text: "Nombre",
					value: "nombre",
					width: "100%",
					cellClass: "pa-1 text-no-wrap fileName",
					dataType: "text",
				},
				{
					text: "Acciones",
					value: "acciones",
					sortable: false,
					filterable: false,
				},
			].map((header) => {
				return {
					class: "text-no-wrap sticky-header",
					cellClass: "pa-1 text-no-wrap",
					filter: (value) =>
						perColumnFilter(value, this.inlineFilters[header.value]),
					...header,
				};
			}),
			inlineFilters: {},
		};
	},
	methods: {
		getFiles() {
			axios({
				url: `${process.env.VUE_APP_OUR_API_URL}/documentacion.php`,
				method: "GET",
				params: {
					token: this.$store.getters.getJwtEmpresa,
				},
			})
				.then((res) => {
					this.documentos = res.data.map((file) => {
						return { nombre: file };
					});
				})
				.catch((err) => console.error(err));
		},
		downloadFile(filename, download) {
			this.downloadingFile = filename;
			axios({
				url: `${process.env.VUE_APP_OUR_API_URL}/documentacion.php`,
				method: "GET",
				params: {
					filename,
					token: this.$store.getters.getJwtEmpresa,
				},
				responseType: "blob",
			})
				.then((res) => {
					let extension = filename
						.split(".")
						[filename.split(".").length - 1].toLowerCase();

					let file = new File(
						[res.data],
						`borrador_${new Date().getTime()}.${extension}`,
						{
							type: extensions[extension],
						}
					);
					downloadFile(file, !download);
					this.downloadingFile = false;
				})
				.catch((err) => console.error(err));
		},
		downloadSelected() {
			this.selected.forEach((file) => {
				this.downloadingFile = true;
				this.downloadFile(file.nombre, true);
			});
		},
	},
	mounted() {
		this.getFiles();
	},
};
</script>

<style scoped>
.v-data-table /deep/ [role="columnheader"] {
	/* background: #f1f1f1 !important; */
}
</style>